export default function checkStep6({ next, store, nextmw }) {
  if (!store.letsConfirm) {
    return next({ 
      name: 'lets-confirm'
    })  
  } else {
    return nextmw()
  }  
}

