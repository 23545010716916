export default function checkStep4({ next, store, nextmw }) {
  if (!store.solutionBeSold) {
    return next({ 
      name: 'will-this-be-sold'
    })  
  } else {
    return nextmw()
  }  
}


