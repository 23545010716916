<template>
  <v-container fluid>

    <v-row mb-3>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <h1>Error 404: Page Not Found</h1>
      </v-col>
    </v-row>

  </v-container>
</template>
