<template>
  <v-container fluid pt-0>
    <v-row mb-3>
      <v-col sm="10" md="9" lg="6" class="mx-auto">
        <steps :steps="5" :current="2" />
      </v-col>
    </v-row>

    <v-row mb-3 v-if="ajaxError">
      <v-col sm="10" md="9" lg="6" class="mx-auto">
        <v-alert
          :value="true"
          type="error"
          closable
        >
          {{ ajaxError }}
        </v-alert>
      </v-col>
    </v-row>

    <v-row mb-3>
      <v-col sm="10" md="9" lg="6" class="mx-auto">
        <h1>Select by Application</h1>
      </v-col>
    </v-row>

    <SearchInput
      input-placeholder-text="Search by brand or application"
      @search="searchQuery($event)"
      @clear="resetCategories()"
    />

    <v-row>
      <v-col sm="10" md="9" lg="6" class="mx-auto">
        <v-expansion-panels
        v-model="panel"
        >

          <v-expansion-panel
            popout
            v-for="(app, i) in applications"
            :key="i"
          >
            <v-expansion-panel-title>
              <h4>{{ app.name }}</h4>
            </v-expansion-panel-title>

            <v-expansion-panel-text>
              <v-row>
                <v-col
                  v-for="(brand, index) in app.brands"
                  :key="index"
                  cols="6"
                  xs="12"
                  sm="6"
                  md="4"
                  lg="4"
                  xl="4"
                >
                  <v-card
                    flat
                    rounded="0"
                    ripple
                    class="clickable pt-6"
                    @click="setBrand(app.id, app.name, app.default_brand, brand)"
                  >
                    <v-img v-if="brand.name === 'Not Sure'"
                      src="../prolead-not-sure-logo.svg"
                      aspect-ratio="1.7778"
                    >
                    </v-img>
                    <v-img v-else
                      :src="brand.icon_logo_path"
                      aspect-ratio="1.7778"  
                    >
                    </v-img>
                    <v-card-title
                      class="justify-center text-center text-uppercase"
                      style="white-space: normal; height: 7rem;"
                    >
                      {{ brand.name }}
                    </v-card-title>
                  </v-card>
                </v-col>
              </v-row>

            </v-expansion-panel-text>

          </v-expansion-panel>

        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row mb-0>
      <v-col sm="10" md="9" lg="6" class="mx-auto">
        <v-alert
          :value="true"
          type="info"
          v-if="activeBrand"
        >
          Your selection:
          <span v-if="activeBrand.name == 'Not Sure'"><strong>{{ activeApplicationName }}</strong> - <strong>({{ activeBrand.name }})</strong></span>
          <strong v-else>{{ activeBrand.name }}</strong>
        </v-alert>
        <v-alert
          :value="true"
          type="info"
          v-else
        >
          You must select an application before continue.
        </v-alert>

      </v-col>
    </v-row>

    <v-row mb-3 v-if="checkBrandError">
      <v-col sm="10" md="9" lg="6" class="mx-auto">
        <v-alert
          :value="true"
          type="error"
          closable
        >
          {{ checkBrandError }}
        </v-alert>
      </v-col>
    </v-row>

    <v-row mb-5>
      <v-col sm="10" md="9" lg="6" class="mx-auto">
        <v-btn
          id="nxt-btn"
          class="text-white mb-3"
          block
          rounded
          variant="flat"
          :disabled="toggleButton"
          size="large"
          color="#171b60"
          @click="checkBrand"
        >
          Next
          <v-icon>mdi-menu-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      class="elevation-24"
      timeout="-1"
      location="top"
      color="rgb(0, 154, 118)"
      vertical
    >
      {{ defaultBrandError }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          variant="text"
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useStore } from '../store/store';
import apiClientService from '../services/apiClientService.js';
import searchTerm from '../components/searchTerm.js';
import { useRouter } from 'vue-router';
import SearchInput from '../components/searchInput.vue';

const store = useStore();
const router = useRouter();

// Data properties
const panel = ref([]);
const activeBrand = ref(null);
const activeDefaultBrand = ref(null);
const activeApplicationName = ref(null);
const activeApplicationId = ref(null);
const ajaxError = ref(false);
const checkBrandError = ref(false);
const applications = ref([]);
const applicationsBackup = ref([]);
const toggleButton = ref(true);
const snackbar = ref(false);
const defaultBrandError = ref(null);

// Methods
const checkBrand = async () => {
  if (store.currentUser.brand.id != activeBrand.value.id) {
          // Brand with id 0 means that's default brand
          if (activeBrand.value.id == 0) {
            apiClientService.userBrands(store.currentUser.id)
              .then(response => {
                if (response.data.includes(activeDefaultBrand.value.id)) {
                  defaultBrandError.value = `Unfortunately, ${activeDefaultBrand.value.name} is the default\
                  brand when you select Not Sure for ${activeApplicationName.value}.\
                  Since ${activeDefaultBrand.value.name} is one of the brands assigned to you in\
                  ProLead you are not able to submit a lead to them. You can select another brand\
                  (if applicable) or just cancel creating this lead by closing your browser window.`
                  snackbar.value = true
                } else
                  router.push({ name: 'sale-already-happened' })
              })
              .catch(error => ajaxError.value = error.message)
          } else
            router.push({ name: 'sale-already-happened' })
        } else
          checkBrandError.value = "You can't select you brand"
};

const setBrand = (applicationId, newactiveApplicationName, newactiveDefaultBrand, brand) => {
        activeBrand.value = brand
        activeDefaultBrand.value = newactiveDefaultBrand
        activeApplicationName.value = newactiveApplicationName
        activeApplicationId.value = applicationId
        toggleButton.value = false
        store.setBrand(brand)
        store.setApplicationId(applicationId)
        store.setPathInfo('By Application')
        scrollToBottom()
        checkBrandError.value = null
};

const scrollToBottom = () => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth'
      });
    };

const searchQuery = (query) => {
  if (query.length >= 2) {
          applications.value = searchTerm(applicationsBackup.value, query)
        } else
          applications.value = applicationsBackup.value
};

const resetCategories = () => {
  applications.value = applicationsBackup.value
        panel.value = []
};

const getApplications = async (brandId) => {
  apiClientService.getApplications(brandId)
          .then(response => {
            if (response.data.length > 0) {
              // Add Not Sure for those categories where are more than two brands and has a defaut brand
              response.data.map((category) => {
                if (category.brands.length >= 2 && category.default_brand != null) {
                  let notSureBrand = {
                    description: 'Not Sure',
                    icon_logo_path: '../assets/prolead-not-sure-logo.svg',
                    id: 0,
                    logo_path: '../assets/prolead-not-sure-logo.svg',
                    name: 'Not Sure'
                  }

                  category.brands.push(notSureBrand)
                }
              })

              // Filter categories that don't any brand
              let data = response.data.filter(application => application.brands.length > 0)

              applications.value = data
              applicationsBackup.value = data
              ajaxError.value = false
            }

            document.getElementById("loading").style.display = "none"
          })
          .catch(error => {
            ajaxError.value = error.message
            document.getElementById("loading").style.display = "none"
          })
};

onMounted(() => {
  document.getElementById("loading").style.display = "block"

      apiClientService.getPrimaryBrand(store.currentUser.id)
        .then(response => {
          store.currentUser.brand = response.data
          ajaxError.value = false

          getApplications(store.currentUser.id)
        })
        .catch(error => {
          ajaxError.value = error.message
        })
});

</script>

<script>
// Components (using classic <script> tag)
export default {
  components: {
    SearchInput
  }
};
</script>

<style scoped>
  @media (max-width: 480px) {
    .v-card-title {
      height: 4.5rem !important;
      font-size: 0.75rem;
      line-height: 1.75 !important;
    }
  }
</style>