<template>
  <v-container fluid>
    <v-row mb-3>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <h1>Bonus Schedule</h1>
      </v-col>
    </v-row>

    <v-row mb-3 v-if="ajaxError">
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <v-alert
          :value="true"
          type="error"
          closable
        >
          {{ ajaxError }}
        </v-alert>
      </v-col>
    </v-row>

    <v-row mb-3>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <h3>
          Look for yourself and we'll send you an email with the details.
        </h3>
      </v-col>
    </v-row>

    <v-row mb-5>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <v-autocomplete
          label="Who are you? Search by last name..."
          :model-value="userSelected"
          @update:model-value="setUser($event)"
          :items="users"
          item-title="full_name"
          item-value="id"
          :loading="isLoading"
        >
        </v-autocomplete>
      </v-col>
    </v-row>

    <v-row mb-3>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <v-btn
          class="text-white mb-3"
          block
          rounded
          variant="flat"
          :disabled="toggleButton"
          size="large"
          color="#171b60"
          @click="submit"
        >
          Send me the details
        </v-btn>
      </v-col>
    </v-row>

    <v-row mb-3 v-if="showAlert">
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <v-alert
          closable
          type="success"
        >
          Details sent!
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
  import { ref, onMounted, watch } from 'vue';
  import { useStore } from '../store/store';
  import apiClientService from '../services/apiClientService';

  const store = useStore();
  const userSelected = ref('Loading...');
  const users = ref([]);
  const ajaxError = ref(false);
  const toggleButton = ref(userSelected.value ? false : true);
  const isLoading = ref(false);
  const showAlert = ref(false);
  const csrfToken = ref('no-csrf-token');
  
  const setUser = (selectedValue) => {
    let selectedUser;

    if (selectedValue === null) {
      return;
    }

    if (typeof selectedValue === 'object' && selectedValue.id) {
      selectedUser = selectedValue;
    } else {
      selectedUser = users.value.find(user => user.id === selectedValue);
    }

    if (selectedUser) {
      const obj = {
        id: selectedUser.id,
        first_name: selectedUser.first_name,
        last_name: selectedUser.last_name
      }
      store.setUserBonusSchedule(obj);
      userSelected.value = obj;
    } else {
      console.error(`User with ID ${selectedValue} not found.`);
    }
  };

  const submit = async () => {
    let selectedUser;
    if (typeof userSelected.value === 'object' && userSelected.value.id) {
      selectedUser = userSelected.value;
    } else {
      selectedUser = users.value.find(user => user.id === userSelected.value);
    }
    showAlert.value = false;
    document.getElementById("loading").style.display = "block";

    try {
      await apiClientService.sendBonusSchedule(selectedUser, csrfToken.value);
      ajaxError.value = false;
      showAlert.value = true;
      document.getElementById("loading").style.display = "none";
      store.setUserBonusSchedule(selectedUser);
    } catch (error) {
      ajaxError.value = error.message;
      document.getElementById("loading").style.display = "none";
    }
  };

  onMounted(async () => {
    if (!users.value.length) {
      isLoading.value = true;
      
      try {
        const response = await apiClientService.getUsers();
        users.value = response.data;
        users.value.forEach(e => {
              e.full_name = e.last_name + ', ' + e.first_name
            });
        userSelected.value = store.userSelectedBonusSchedule.id;
        ajaxError.value = false;
      } catch (error) {
        ajaxError.value = error.message;
      } finally {
        isLoading.value = false;
      }
    }
  });

  watch(userSelected, () => {
    toggleButton.value = userSelected.value ? false : true;
  });
</script>
