<template>
  <v-container fluid>
    <v-row mb-3>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <h1>{{ title }}</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <div v-html="description"></div>
      </v-col>
    </v-row>

    <v-row>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <v-expansion-panels ref="expansionPanels" v-model="openedPanel">
          <v-expansion-panel
            v-for="faq in faqs"
            :key="faq.id"
            :id="`faq-${faq.id}`"
            class="d-print-none"
          >
            <v-expansion-panel-title>
              {{ faq.title }}
            </v-expansion-panel-title>
            <v-expansion-panel-text class="pa-4 d-print-inline-block">
            <div class="container">
              <div class="pb-5" v-html="faq.description"></div>
              <v-btn size="small" class="copy-btn" @click="copyFaqLink(faq.title, faq.id)">
                {{ copyButtonTexts[faq.id] || 'Copy Link' }}
              </v-btn>
            </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
        <br />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiClientService from '../services/apiClientService'

export default {
  data() {
    return {
      faqs: [],
      title: '',
      description: '',
      openedPanel: null,
      copyButtonTexts: {}
    }
  },
  mounted() {
    this.fetchFAQs().then(() => {
      const urlParams = new URLSearchParams(window.location.search)
      const faqSlug = urlParams.get('faq')
      if (faqSlug) {
        this.openFaq(faqSlug)
      }
    })
  },
  methods: {
    async fetchFAQs() {
      const response = await apiClientService.getFAQs()
      this.faqs = response.data.faqs
      this.title = response.data.editable_page.title
      this.description = response.data.editable_page.description
    },
    copyFaqLink(faqTitle, faqId) {
      const slug = this.slugify(faqTitle)
      const url = `${window.location.origin}${window.location.pathname}?faq=${slug}`
      navigator.clipboard.writeText(url).then(() => {
        this.copyButtonTexts[faqId] = 'Copied!'

        setTimeout(() => {
          this.copyButtonTexts[faqId] = 'Copy Link'
        }, 2000)
      })
    },
    slugify(text) {
      return text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/[^\w-]+/g, '') // Remove all non-word chars
        .replace(/--+/g, '-') // Replace multiple - with single -
        .trim() // Trim
    },
    openFaq(faqSlug) {
      const index = this.faqs.findIndex((faq) => this.slugify(faq.title) === faqSlug)
      const faqId = this.faqs[index].id
      if (index !== -1) {
        this.$nextTick(() => {
          this.openedPanel = [index]
          this.scrollToFaq(faqId)
        })
      }
    },
    scrollToFaq(faqId) {
      this.$nextTick(() => {
        const faqElementId = `faq-${faqId}`
        const faqElement = document.getElementById(faqElementId)
        if (faqElement) {
          faqElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
      })
    }
  }
}
</script>

<style scoped>
img {
  max-width: 100%;
  height: auto;
}

.container {
  display: flex;
  flex-direction: column;
}

.copy-btn {
  align-self: flex-end;
  width: max-content;
}
</style>
