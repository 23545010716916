import axios from 'axios'

const apiClient = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  withCredentials: false,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

apiClient.interceptors.response.use((response) => response, (error) => {
  if (typeof error.response === 'undefined') {
    window.location.href = '/maintenance/'
  }
})

export default {
  checkInvalidEmail(userId) {
    return apiClient.get('/invalid_email', {
      params: {
        user_id: userId
      }
    })
  },
  getUsers() {
    return apiClient.get('/users')
  },
  getUsersFromBrand(brandId) {
    return apiClient.get('/get_users_from_brand', {
      params: {
        brand_id: brandId
      }
    })
  },
  getUsersProMachSolutions(idBrandPromachSolutions) {
    return apiClient.get('/get_users_pro_mach_solutions', {
      params: {
        id_brand_promach_solutions: idBrandPromachSolutions
      }
    })
  },
  getPrimaryBrand(userId) {
    return apiClient.get('/primary_brand_by_user', {
      params: {
        user_id: userId
      }
    })
  },
  getBrands(userId) {
    return apiClient.get('/brands', {
      params: {
        user_id: userId
      }
    })
  },
  getBrandsWithoutOnesUserHas(userId) {
    return apiClient.get('/brands/' + userId + '/without_user_brands')
  },
  getApplications(brandId) {
    return apiClient.get('/product_categories', {
      params: {
        user_id: brandId
      }
    })
  },
  postLead(lead, application_id, csrfToken) {
    apiClient.defaults.headers.common['X-CSRF-Token'] = csrfToken
    return apiClient.post('/leads', {
      lead,
      application_id
    })
  },
  sendBonusSchedule(user, csrfToken) {
    apiClient.defaults.headers.common['X-CSRF-Token'] = csrfToken
    return apiClient.get('/send_bonus_schedule', {
      params: {
        user_id: user.id
      }
    })
  },
  userBrands(userId) {
    return apiClient.get('/users/' + userId + '/brands')
  },
  getFAQs() {
    return apiClient.get('/faqs?page=faqs')
  },
  getGMFAQs() {
    return apiClient.get('/faqs?page=gm')
  },
  getTrainingGuide() {
    return apiClient.get('/dynamic_pages')
  }
}