<template>
  <v-container fluid pt-0>
    <v-row mb-3>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <steps :steps="5" :current="4" />
      </v-col>
    </v-row>

    <v-row mb-3>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <h1>Will this solution be sold:</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <v-btn class="text-white mb-3" block rounded variant="flat" color="#171b60" @click="solutionBeSold('directToCustomer')">
          Direct to Customer/Distributor
          <v-icon>mdi-menu-right</v-icon>
        </v-btn>

        <v-btn class="text-white mb-3" block rounded variant="flat" color="#171b60" @click="snackbar = true">
          Direct to {{ currentUserBrand.name }}
          <v-icon>mdi-menu-right</v-icon>
        </v-btn>

        <v-btn class="text-white mb-3" block rounded variant="flat" color="#171b60" @click="solutionBeSold('dontKnow')">
          Don't Know
          <v-icon>mdi-menu-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      class="elevation-24"
      timeout="-1"
      location="top"
      color="rgb(0, 154, 118)"
      vertical
    >
      {{ text }}

      <template v-slot:actions>
        <v-btn
          color="white"
          variant="text"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script scoped>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '../store/store'

export default {
  setup() {
    const router = useRouter()
    const store = useStore()

    const snackbar = ref(false)
    const text = ref("Unfortunately, this isn't quite a ProLead. Equipment sold directly to your division does not qualify. However, you will still receive your standard commission for the total value of all equipment you sell from both your division and any other ProMach division.")

    const currentBrand = computed(() => store.currentBrand)
    const currentUserBrand = computed(() => store.currentUser.brand)

    const solutionBeSold = (channel) => {
      store.setSolutionBeSold(channel)
      router.push({ name: 'customer-info' })
    }

    return {
      snackbar,
      text,
      currentBrand,
      currentUserBrand,
      solutionBeSold
    }
  }
}
</script>

<style>
  .v-snackbar__content {
    font-weight: bold !important;
    font-size: 1rem !important;
  }
</style>
