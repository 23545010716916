export default function checkStep5({ next, store, nextmw }) {
  if (!store.customerInfo.name) {
    return next({ 
      name: 'customer-info'
    })  
  } else {
    return nextmw()
  }  
}


