<template>
  <v-container pt-0>
    <v-row mb-3>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <steps :steps="5" :current="1" />
      </v-col>
    </v-row>

    <v-row mb-3 v-if="ajaxError">
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <v-alert
          :value="true"
          type="error"
          closable
        >
          {{ ajaxError }}
        </v-alert>
      </v-col>
    </v-row>

    <v-row mb-3>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <h1>Let's enter a ProLead</h1>
      </v-col>
    </v-row>

    <v-row mb-3>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <p class="text-body-2">
          Just a few things to remember - this should be a real lead with
          a real customer/prospect that has expressed a real interest in
          whatever you are about to enter. Don't put cold guesses in
          here, make sure it's legit. Now let's get started!
        </p>
      </v-col>
    </v-row>

    <v-row mb-5>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
<v-autocomplete
  label="Who are you? Search by last name..."
  :model-value="currentUser"
  @update:model-value="setUser($event)"
  :items="users"
  :loading="isLoading"
  item-title="full_name"
  item-value="id"
>
</v-autocomplete>

        <p class="text-center text-grey mt-2">Don't see your name? <a href="mailto:system@prolead.com?subject=Request for ProLead Access">Email us</a></p>
      </v-col>
    </v-row>

    <v-row mb-5>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <v-btn
          class="text-white mb-3"
          block
          rounded
          variant="flat"
          :disabled="toggleButton"
          size="large"
          color="#171b60"
          @click="leapfrogByBrand()"
        >
          Next
          <v-icon>mdi-menu-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      class="elevation-24"
      timeout="-1"
      location="top"
      color="rgb(0, 154, 118)"
      vertical
    >
      {{ defaultBrandError }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          variant="text"
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-container>
</template>

<script scoped>
import { ref, onMounted } from 'vue'
import { useStore } from '../store/store'
import apiClientService from '../services/apiClientService'
import 'vuetify/dist/vuetify.min.css';
import { useRouter } from 'vue-router'
export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  setup() {
    // Reactive data properties
    const store = useStore()
    const users = ref([])
    const ajaxError = ref(false)
    const toggleButton = ref(false)
    const prevRoute = ref(null)
    const isLoading = ref(false)
    const snackbar = ref(false)
    const defaultBrandError = ref(null)
    const currentUser = ref('Loading...');
    const router = useRouter();

    // Methods
    const setUser = (selectedValue) => {
    let selectedUser;

    if (selectedValue === null) {
      return;
    }
    
    if (typeof selectedValue === 'object' && selectedValue.id) {
      selectedUser = selectedValue;
    } else {
      selectedUser = users.value.find(user => user.id === selectedValue);
    }

    if (selectedUser) {
      const obj = {
        id: selectedUser.id,
        first_name: selectedUser.first_name,
        last_name: selectedUser.last_name
      }
      useStore().setUser(obj);
      currentUser.value = obj.id;
    } else {
      console.error(`User with ID ${selectedValue} not found.`);
    }
  }

    const leapfrogByBrand = () => {
      const store = useStore()

      if (store.currentBrand && prevRoute.value.name == 'promach-solutions') {
        apiClientService.userBrands(store.currentUser.id)
          .then(response => {
            if (response.data.includes(store.currentBrandProMachSolutions)) {
              defaultBrandError.value = `Unfortunately, ${store.currentBrand.name} \
              is a brand assigned to you in ProLead and you are not able to submit a lead\
              to any brand you are assigned to within the ProLead System. You can select another brand\
              from the ProMach Solutions page (if applicable) or just cancel creating\
              this lead by closing your browser window.`
              snackbar.value = true
            } else {
              apiClientService.getPrimaryBrand(store.currentUser.id)
              .then(response => {
                store.currentUser.brand = response.data
                ajaxError.value = false
                router.push({ name: 'sale-already-happened' })
              })
              .catch(error => ajaxError.value = error.message)
            }
          })
          .catch(error => ajaxError.value = error.message)
        } else {
        router.push({ name: 'who-is-this-for' })
      }
    }

    // Lifecycle hooks
    onMounted(() => {
      if (!users.value.length) {
        isLoading.value = true
        apiClientService.getUsersProMachSolutions(null)
        .then(response => {
          users.value = response.data
          users.value.forEach(e => {
            e.full_name = e.last_name + ', ' + e.first_name
          });
          ajaxError.value = false
          currentUser.value = store.currentUser.id;
          })
          .catch(error => {
            ajaxError.value = error.message
          }).finally(() => {
            isLoading.value = false
          })
      }
    })

    // Return data and methods for the template
    return {
      users,
      ajaxError,
      toggleButton,
      prevRoute,
      isLoading,
      snackbar,
      defaultBrandError,
      setUser,
      leapfrogByBrand,
      currentUser
    }
  }
}
</script>

