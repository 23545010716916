<template>
  <v-container fluid pt-0>
    <v-row mb-3>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <steps :steps="5" :current="3" />
      </v-col>
    </v-row>

    <v-row mb-3>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <h1>Has the sale already happened?</h1>
      </v-col>
    </v-row>

    <v-row mb-3>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <v-radio-group
          variant="filled"
          v-model="alreadyHappened"
          @update:model-value="setAlreadyHappened"
        >
          <v-radio label="No" value="false"></v-radio>
          <v-radio label="Yes" value="true"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

  </v-container>
</template>

<script scoped>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '../store/store'

export default {
  setup() {
    const router = useRouter()
    const alreadyHappened = ref(false)
    const toggleButton = ref(true)

    const setAlreadyHappened = (yepNope) => {
      toggleButton.value = false
      useStore().setAlreadyHappened(yepNope)
      router.push({ name: 'will-this-be-sold' })
    }

    return {
      alreadyHappened,
      setAlreadyHappened
    }
  }
}
</script>
