<template>
  <div class="maintenance">
    <title>Site Maintenance</title>
    <article>
      <h1>We&rsquo;ll be back soon.</h1>
      <div>
        <p>Sorry for the inconvenience.We&rsquo;re performing maintenance to our systems and should be back momentarily.</p>
        <p>&mdash;The ProLead Team</p>
      </div>
    </article>
  </div>
</template>

<script>
import axios from 'axios'

const apiClient = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  withCredentials: false,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

export default {
  methods: {
    checkStatus: function() {
      apiClient.get('/users')
        .then(response => {
          if (response.status === 200) {
            window.location.href = '/'
          }
        })
        .catch(error => this.ajaxError = error.message);
    }
  },
  beforeMount() {
    this.checkStatus()
  }
}

</script>

<style scoped>
.maintenance {
  text-align:center;
  padding:150px;
  font:20px Helvetica,sans-serif;
  color:#333;
}

h1 {
  font-size:50px
}

article {
  display:block;
  text-align:left;
  width:650px;
  margin:0 auto
}
</style>