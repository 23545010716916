<template>
  <v-container fluid>
    <v-row mb-3 v-if="ajaxError">
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <v-alert
          :value="true"
          type="error"
          closable
        >
          {{ ajaxError }}
        </v-alert>
      </v-col>
    </v-row>

    <v-row mb-3>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <h1>ProMach Solutions</h1>
      </v-col>
    </v-row>

    <v-row mb-3>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <p class="text-body-2">Select a product brand to begin entering a lead for them.</p>
      </v-col>
    </v-row>

    <v-row>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <v-card
          v-for="(solution, index) in solutions"
          :key="index"
          flat
        >
          <v-img
            :src="solution.logo_path"
            aspect-ratio="1.7778"
          >
          </v-img>

          <v-card-title primary-title>
            <div>{{ solution.description }}</div>
          </v-card-title>

          <v-card-actions>
            <v-btn
              class="text-white mb-3"
              block
              rounded
              variant="flat"
              size="large"
              color="#171b60"
              @click="setBrand(solution)"
            >
              Add lead for {{ solution.name }}
              <v-icon end>mdi-plus</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup scoped>
import { ref, onMounted } from 'vue';
import { useStore } from '../store/store';
import apiClientService from '../services/apiClientService';
import { useRouter } from 'vue-router';

const ajaxError = ref(false);
const solutions = ref([]);

const store = useStore();
const router = useRouter();

const fetchBrands = async () => {
  try {
    const response = await apiClientService.getBrands();
    solutions.value = response.data;
    ajaxError.value = false;
  } catch (error) {
    ajaxError.value = error.message;
  }
};

onMounted(fetchBrands);

const setBrand = (brand) => {
  store.setBrand(brand);
  store.setBrandProMachSolutions(brand.id);
  store.setPathInfo('By Solution');
  router.push({ name: 'who-are-you' });
};
</script>

