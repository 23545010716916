<template>
  <v-container fluid>
    <v-row mb-3>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <h1>Almost Done!</h1>
      </v-col>
    </v-row>

    <v-row mb-3 v-if="ajaxError">
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <v-alert
          :value="true"
          type="error"
          closable
        >
          {{ ajaxError }}
        </v-alert>
      </v-col>
    </v-row>

    <v-row mb-3>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <p>You'll receive an email shortly to validate this lead submission. <strong>Until the YES link in the email is clicked, this lead will not be pushed through the system.</strong></p>
        <p><em>If it becomes a successful sale, you'll be eligible for a bonus. We'll keep you updated on how it's doing via email notifications.</em></p>
      </v-col>
    </v-row>
 
    <v-row mb-5 mt-3>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <v-btn
          class="text-white mb-3"
          block
          rounded
          variant="flat"
          size="large"
          color="#171b60"
          click=""
          :to="{ name: 'who-is-this-for' }"
        >
          Enter Another ProLead
          <v-icon>mdi-menu-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup scoped>
import { ref, onMounted } from 'vue';
import { useStore } from '../store/store'
import apiClientService from '../services/apiClientService';

const ajaxError = ref(null);
const store = useStore();

const checkInvalidEmail = async () => {
  if (store.user_id) {
    try {
      const response = await apiClientService.checkInvalidEmail(store.user_id);
      if (response.data.length === 1) {
        ajaxError.value = 'Your email address is inactive, you will not receive an email to validate the lead. Admins have been notified and will contact you shortly.';
      }
    } catch (error) {
      ajaxError.value = error.message;
    } finally {
      store.clearUserId();
    }
  }
};

onMounted(() => {
  checkInvalidEmail();
});
</script>