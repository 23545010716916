<template>
  <v-container fluid>
    <v-row mb-3>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <h1>Let's confirm</h1>
      </v-col>
    </v-row>

    <v-row mb-3 v-if="ajaxError">
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <v-alert
          :value="true"
          type="error"
          closable
        >
          {{ ajaxError }}
        </v-alert>
      </v-col>
    </v-row>

    <v-row mb-3>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">

        <v-list
          lines="two"
        >
          <v-list-item>
            <v-list-item>
              <v-list-item-subtitle>You are:</v-list-item-subtitle>
              <v-list-item-title>
                {{ store.currentUser.first_name }} 
                {{ store.currentUser.last_name }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item>
            <v-list-item>
              <v-list-item-subtitle>Submitting a lead for:</v-list-item-subtitle>
              <v-list-item-title>
                {{ store.customerInfo.company }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item>
            <v-list-item>
              <v-list-item-subtitle>Submitting the lead to:</v-list-item-subtitle>
              <v-list-item-title v-if="store.currentBrand">
                {{ store.currentBrand.name }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item>

          <v-divider></v-divider>
        </v-list>

        <v-checkbox
          v-model="letsConfirm"
          label="I agree that this customer info is valid, my customer contact has been qualified, and they are expecting a sales rep to follow up.">
        </v-checkbox>

      </v-col>
    </v-row>
 
     <v-row mb-5 mt-3>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <v-btn
          class="text-white mb-3"
          block
          rounded
          variant="flat"
          :disabled="!letsConfirm"
          size="large"
          color="#171b60"
          @click="submitProLead"
        >
          Submit ProLead
          <v-icon>mdi-menu-right</v-icon>
        </v-btn>

        <p class="text-center text-grey mt-2">Use the Back button if you need to make changes</p>

      </v-col>
    </v-row>

  </v-container>
</template>

<script setup scoped>
import { ref } from 'vue';
import { useStore } from '../store/store'
import apiClientService from '../services/apiClientService';
import { useRouter } from 'vue-router';

const store = useStore();
const letsConfirm = ref(false);
const ajaxError = ref(null);
const router = useRouter();

const checkIframe = () => {
        if (window.self !== window.top) {
          var parentUrl = document.referrer;
          if (parentUrl.includes('lightning.force.com')) {
            return ' - From Salesforce'
          } else {
            return ''
          }
        } else {
          return ''
        }
      };

    const directToCustomer = () => {
        if (store.solutionBeSold === 'directToCustomer') {
          return true
        } else {
          return false
        }
      };

const submitProLead = async () => {
  const lead = {
    user_id: store.currentUser.id,
    brand_id: store.currentBrand.id,
    customer_company: store.customerInfo.company,
          customer_contact_name: store.customerInfo.name,
          customer_phone: store.customerInfo.phone,
          customer_email: store.customerInfo.email,
          customer_address: store.customerInfo.address,
          customer_city: store.customerInfo.city,
          customer_state: store.customerInfo.state,
          customer_zip: store.customerInfo.zip,
          customer_country: store.customerInfo.country,
          opportunity_details: store.customerInfo.opportunityDetails,
          dist_agent_rep: store.customerInfo.distributorAgentRep,
          direct_lead_to: store.customerInfo.directLeadTo,
          sale_happened: store.alreadyHappened,
          path_information: store.pathInformation + checkIframe(),
          product_categories_id: store.currentApplicationId,
          direct_to_customer: directToCustomer(),
  };

  if (emptyLeadValues(lead)) {
    ajaxError.value = 'Missing data from customer info, please go back and check every field';
  } else {
    try {
      document.getElementById("loading").style.display = "block";
      const response = await apiClientService.postLead(lead, store.currentApplicationId, 'no-csrf-token');
      store.setUserId(response.data.user_id);
      store.setLetsConfirm(letsConfirm.value);
      document.getElementById("loading").style.display = "none";
      ajaxError.value = null;
      // Navigate to the next page
      router.push({ name: 'almost-done' });
    } catch (error) {
      ajaxError.value = error.message;
      document.getElementById("loading").style.display = "none";
    }
  }
};

const emptyLeadValues = (lead) => {
  if (!lead.user_id || !lead.customer_company ||
        !lead.customer_contact_name || !lead.customer_phone || !lead.customer_email ||
        !lead.customer_address || !lead.customer_city || !lead.customer_state || !lead.customer_zip ||
        !lead.customer_country || !lead.opportunity_details) {
          return true
        }

        return false
};
</script>