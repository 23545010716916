<template>
  <div>
    <!-- /root-element-fix -->
    <v-container>
      <v-row>
        <v-col sm="8" offset-sm="2" md="6" offset-md="3">
          <button @click="goTo('who-are-you')" class="custom-btn">
            Enter a ProLead
            <v-icon end size="xx-large">mdi-keyboard-variant</v-icon>
          </button>
          <button @click="goTo('bonus-schedule')" class="custom-btn">
            Bonus Schedule
            <v-icon end size="xx-large">mdi-cash-multiple</v-icon>
          </button>

          <button @click="goTo('training-guide')" class="custom-btn">
            Training &amp; FAQs
            <v-icon end size="xx-large">mdi-account-question-outline</v-icon>
          </button>

          <button @click="goTo('promach-solutions')" class="custom-btn">
            ProMach Solutions<v-spacer></v-spacer>
            <v-icon end size="xx-large">mdi-lightbulb-on-outline</v-icon>
          </button>

          <button @click.stop="openModal" class="custom-btn">
            Add Bookmark/Shortcut
            <v-icon end size="xx-large">mdi-bookmark-plus-outline</v-icon>
          </button>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-dialog
          v-model="bookmarkDialog"
          fullscreen
          :scrim="false"
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dark color="#009a76" flat class="d-print-none">
              <v-toolbar-title>Add Bookmark/Shortcut</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-btn icon @click="closeModal">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-title
              >Follow the instructions below to add a shortcut to a website on the home screen of
              your iPad, iPhone, or Android devices.</v-card-title
            >

            <v-card-text>
              <h3>iPad or iPhone</h3>
              <ol>
                <li>Launch “Safari” app. This does not work from the “Chrome” app.</li>
                <li>
                  Enter into the address field the URL of the website you want to create a shortcut
                  to. Tap “Go.”
                </li>
                <li>
                  Tap the icon featuring a right-pointing arrow coming out of a box along the top of
                  the Safari window to open a drop-down menu.
                </li>
                <li>
                  Tap “Add to Home Screen.” The Add to Home dialog box will appear, with the icon
                  that will be used for this website on the left side of the dialog box.
                </li>
                <li>
                  Enter the name for the shortcut using the on-screen keyboard and tap “Add.” Safari
                  will close automatically and you will be taken to where the icon is located on
                  your iPad’s desktop.
                </li>
              </ol>

              <h3>Android</h3>
              <ol>
                <li>Launch “Chrome” app.</li>
                <li>Open the website or web page you want to pin to your home screen.</li>
                <li>
                  Tap the menu icon (3 dots in upper right-hand corner) and tap Add to homescreen.
                </li>
                <li>
                  You’ll be able to enter a name for the shortcut and then Chrome will add it to
                  your home screen.
                </li>
              </ol>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
  </div>
  <!-- /root-element-fix -->
</template>

<script>
import { watch } from 'vue';

export default {
  data() {
    return {
      bookmarkDialog: false,
    };
  },
  methods: {
    openModal() {
      this.$router.push({ query: { modal: 'open' } });
    },
    closeModal() {
      this.$router.push({ query: {} });
    },
    goTo(routeName) {
      this.$router.push({ name: routeName });
    }
  },
  mounted() {
    watch(
      () => this.$route.query.modal,
      (newValue) => {
        this.bookmarkDialog = newValue === 'open';
      },
      { immediate: true }
    );
  }
}
</script>



<style scoped>
.container {
  max-width: 100%;
}

.row {
  display: flex;
  justify-content: center;
}

.col {
  max-width: 50%;
}

.custom-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 6px 0 20px;
  margin-bottom: 12px;
  width: 100%;
  height: 44px;
  background-color: #171b60;
  color: white;
  border-radius: 50px;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  transition: opacity 0.2s ease-in-out;
}

.custom-btn:hover {
  opacity: .92;
}

.icon i {
  font-size: 24px;
}

.mdi {
  font-family: 'Material Design Icons';
}
</style>
