import steps from './components/steps.vue'
import loading_spinner from './components/spinner.vue'
import * as Sentry from '@sentry/vue';

import { createApp } from 'vue'

import App from './App.vue'
import router from './router'

import pinia from './store/store.js'
import 'vuetify/dist/vuetify.min.css'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'

const vuetify = createVuetify({
  components: {
    ...components
  },
  icons: {
    iconfont: 'mdi'
  },
  directives,
})


const app = createApp(App)
app.provide('vuetify', vuetify)
app.component('steps', steps)
app.component('loading_spinner', loading_spinner)
app.use(router)
app.use(vuetify)
app.use(pinia)

if (import.meta.env.VITE_NODE_ENV === 'production' || import.meta.env.VITE_NODE_ENV === 'staging') {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_NODE_ENV,
    // Performance Monitoring
    tracesSampleRate: 0, // Capture 100% of the transactions, reduce in production!
  });
};

app.mount('#app')
