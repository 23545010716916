<template>
  <v-container fluid>
    <v-row mb-3>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <h1> {{ trainingGuideTitle }} </h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <div v-if="trainingGuideContent" v-html="trainingGuideContent"></div>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import { ref, onMounted } from 'vue';
import apiClientService from '../services/apiClientService.js';

export default {
  setup() {
    const trainingGuideTitle = ref('');
    const trainingGuideContent = ref('');
    const ajaxError = ref(false);

    const getTrainingGuideVideo = () => {
      apiClientService.getTrainingGuide()
        .then(response => {
          if (response && response.data) {
            trainingGuideTitle.value = response.data.training_guide.title;
            trainingGuideContent.value = response.data.training_guide.description;
          }
        })
        .catch(error => {
          ajaxError.value = error.message;
        });
    };

    onMounted(() => {
      getTrainingGuideVideo();
    });

    return {
      trainingGuideTitle,
      trainingGuideContent,
      ajaxError,
    };
  }
}
</script>

<style module>
iframe {
  max-width: 100%;
}
</style>
