<template>
  <v-container fluid pt-0>
    <v-row mb-3>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <steps :steps="5" :current="2" />
      </v-col>
    </v-row>

    <v-row mb-3>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <h1>Who is this for?</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <v-btn
          class="text-white mb-3"
          block
          rounded
          variant="flat"
          size="large"
          color="#171b60"
          :to="{ name: 'by-brand' }"
        >
          Select by Brand
          <v-icon>mdi-menu-right</v-icon>
        </v-btn>
        <v-btn
          class="text-white mb-3"
          block
          rounded
          variant="flat"
          size="large"
          color="#171b60"
          :to="{ name: 'by-application' }"
        >
          Select by Application
          <v-icon>mdi-menu-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

  </v-container>
</template>

<script setup scoped>
</script>