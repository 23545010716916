import { createPinia, defineStore, setActivePinia } from 'pinia'
import { useStorage } from '@vueuse/core';

// import { version } from '../../package.json'

const pinia = createPinia()

    setActivePinia(pinia)
export const useStore = defineStore('main', {
  state: () => ({
    packageVersion: useStorage('packageVersion', 1),
    alreadyHappened: useStorage('alreadyHappened', false),
    alreadyHappenedSelected: useStorage('alreadyHappenedSelected', null),
    currentBrand: useStorage('currentBrand', {
      id: null,
      name: null,
      description: null,
    }),
    currentApplicationId: useStorage('currentApplicationId', null),
    currentBrandProMachSolutions: useStorage('currentBrandProMachSolutions', null),
    currentUser: useStorage('currentUser', {
      id: null,
      first_name: null,
      last_name: null,
    }),
    customerInfo: useStorage('customerInfo', {
      company: '',
      name: '',
      phone: '',
      email: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      country: 'United States',
      opportunityDetails: '',
      distributorAgentRep: false,
      directLeadTo: {
        id: null,
        full_name: null,
      }
    }),
    letsConfirm: useStorage('letsConfirm', false),
    solutionBeSold: useStorage('solutionBeSold', null),
    loading: useStorage('loading', false),
    pathInformation: useStorage('pathInformation', null),
    userSelectedBonusSchedule: useStorage('userSelectedBonusSchedule', {
      id: null,
      first_name: null,
      last_name: null,
    }),
    user_id: useStorage('user_id', null),
  }),
  actions: {
    setUser(currentUser) {
      this.currentUser = currentUser;
    },
    setUserBonusSchedule(userSelected) {
      this.userSelectedBonusSchedule = userSelected
    },
    setBrand(currentBrand) {
      this.currentBrand = currentBrand
    },
    setApplicationId(currentApplicationId) {
      this.currentApplicationId = currentApplicationId
    },
    setBrandProMachSolutions(currentBrand) {
      this.currentBrandProMachSolutions = currentBrand
    },
    setAlreadyHappened(alreadyHappened) {
      this.alreadyHappened = alreadyHappened
      this.alreadyHappenedSelected = true
    },
    setSolutionBeSold(channel) {
      this.solutionBeSold = channel
    },
    setCustomerInfo(customerInfo) {
      this.customerInfo = customerInfo
    },
    setPathInfo(pathInfo) {
      this.pathInformation = pathInfo
    },
    setLetsConfirm(letsConfirm) {
      this.letsConfirm = letsConfirm
      this.alreadyHappened = false
      this.alreadyHappenedSelected = null
      this.currentBrand = null
      this.currentApplicationId = null
      this.currentBrandProMachSolutions = null
      this.customerInfo = {
        company: '',
        name: '',
        phone: '',
        email: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: 'United States',
        opportunityDetails: '',
        distributorAgentRep: false,
        directLeadTo: null,
      }
      this.solutionBeSold = null
      this.pathInformation = null
    },
    setUserId(userId) {
      this.user_id = userId
    },
    clearUserId() {
      this.user_id = null
    },
    setLoading(status) {
      this.loading = status
    },
    clearCustomerInfo() {
      this.customerInfo = {
        company: '',
        name: '',
        phone: '',
        email: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: 'United States',
        opportunityDetails: '',
        distributorAgentRep: false,
        directLeadTo: {
          id: null,
          full_name: null,
        },
      }
      this.letsConfirm = false
      this.loading = false
    }
  },
})

export default pinia