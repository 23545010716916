function searchTerm(brandsBackup, query) {
  return brandsBackup.filter((data) => {
    return Object.keys(data).some((key) => {
      if(key == 'name' || key == 'brands')
        return JSON.stringify(data[key]).toLowerCase().replace(/[^a-zA-Z0-9]/g, '').trim().includes(query.trim().toLowerCase().replace(/[^a-zA-Z0-9]/g, ''))
    })
  })
}

export default searchTerm
