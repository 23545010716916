export default function checkStep3({ next, store, nextmw }) {
  if (!store.alreadyHappenedSelected) {
    return next({ 
      name: 'sale-already-happened'
    })  
  } else {
    return nextmw()
  }  
}


