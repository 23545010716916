<template>
  <div class="elevation-0 bg-transparent v-stepper theme--light" :steps="steps" :current="current">
    <div class="v-stepper__header">
      <div v-for="step in steps" :key="step" class="v-stepper__step pa-0">
        <span :class="[{ primary: (step === current) }, 'v-stepper__step__step']">
          {{ step }}
        </span>
      </div><!-- /.v-stepper__step -->
    </div><!-- /.v-stepper__header -->
  </div><!-- /.elevation-0.transparent.v-stepper.theme--light-->
</template>

<script>
  export default {
    props: {
      steps: {
        type: Number,
        default: 5
      },
      current: {
        type: Number,
        default: 1
      }
    }
  }
</script>

<style scoped>
  .v-stepper__step {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding: 24px;
    position: relative;
  }

  .v-stepper__step__step {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 50%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: .75rem;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 24px;
    min-width: 24px;
    width: 24px;
    -webkit-transition: .3s cubic-bezier(.25,.8,.25,1);
    transition: .3s cubic-bezier(.25,.8,.25,1);
}

  .theme--light.v-stepper .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__step__step {
    background: rgba(255, 255, 255, 1) !important; /* #747b83 */
    color: #747b83 !important;
    border: 1px solid #747b83 !important;
  }

  .theme--light.v-stepper .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__step__step.primary {
    background: rgba(116, 123, 131, 1) !important; /* #747b83 */
    color: #fff !important;
  }
  .v-stepper__header {
    height: 72px;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
</style>