<template>
  <v-container fluid pt-0>
    <v-row mb-3>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <steps :steps="5" :current="5" />
      </v-col>
    </v-row>

    <v-row mb-3>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <h1>Customer Info</h1>
      </v-col>
    </v-row>

    <v-row mb-3>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">

        <v-form ref="customerInfoForm">
          <v-text-field
            v-model="customerInfo.company"
            :rules="[() => !!customerInfo.company.trim() || 'This field is required', emojiRule]"
            :error-messages="errorMessages"
            label="Company"
            required
            variant="filled"
          ></v-text-field>

          <v-text-field
            v-model="customerInfo.name"
            :rules="[() => !!customerInfo.name.trim() || 'This field is required', emojiRule]"
            :error-messages="errorMessages"
            label="Name"
            required
            variant="filled"
          ></v-text-field>

          <v-text-field
            v-model="customerInfo.phone"
            :rules="[() => !!customerInfo.phone.trim() || 'This field is required', emojiRule]"
            :error-messages="errorMessages"
            label="Phone"
            required
            variant="filled"
          ></v-text-field>

          <v-text-field
            v-model="customerInfo.email"
            :rules="emailRules"
            :error-messages="errorMessages"
            label="Email"
            required
            variant="filled"
          ></v-text-field>

          <v-text-field
            v-model="customerInfo.address"
            :rules="[() => !!customerInfo.address.trim() || 'This field is required', emojiRule]"
            :error-messages="errorMessages"
            label="Address"
            variant="filled"
            required
          ></v-text-field>
          
          <v-text-field
            v-model="customerInfo.city"
            :rules="[() => !!customerInfo.city.trim() || 'This field is required', emojiRule]"
            :error-messages="errorMessages"
            label="City"
            variant="filled"
            required
          ></v-text-field>

          <v-text-field
            v-model="customerInfo.state"
            :rules="[() => !!customerInfo.state.trim() || 'This field is required', emojiRule]"
            label="State/Province/Region"
            variant="filled"
            required
          ></v-text-field>

          <v-text-field
            v-model="customerInfo.zip"
            :rules="[() => !!customerInfo.zip.trim() || 'This field is required', emojiRule]"
            label="ZIP/Postal Code"
            variant="filled"
            required
          ></v-text-field>

          <v-autocomplete
            v-model="customerInfo.country"
            :rules="[v => !!v && !!v.trim() || 'This field is required']"
            :items="countries"
            label="Country"
            variant="filled"
            required
          ></v-autocomplete>

          <v-textarea
            ref="opportunityDetails"
            v-model="customerInfo.opportunityDetails"
            label="Opportunity Details"
            :rules="[() => !!customerInfo.opportunityDetails.trim() || 'This field is required', emojiRule]"
            required
            variant="filled"
          ></v-textarea>

          <v-list-subheader class="pa-0">
            If you're already working with someone at the ProMach product brand that will receive this ProLead
          </v-list-subheader>

          <v-autocomplete
            ref="directLeadTo"
            v-model="customerInfo.directLeadTo"
            :items="usersDirectLead"
            label="Direct Lead To"
            :loading="isLoading"
            variant="filled"
            item-title="full_name"
            item-value="id"
          >
          </v-autocomplete>

          <v-radio-group
            v-model="customerInfo.distributorAgentRep"
            label="Is the customer a distributor, agent, or representative?"
            mandatory
          >
            <v-radio label="Yes" v-bind:value="true"></v-radio>
            <v-radio label="No" v-bind:value="false"></v-radio>
          </v-radio-group>

        </v-form>

      </v-col>
    </v-row>

    <v-row mb-5 mt-3>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3">
        <v-btn
          class="text-white mb-3"
          block
          rounded
          variant="flat"
          size="large"
          color="#171b60"
          @click="submit"
        >
          Confirm
          <v-icon>mdi-menu-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

  </v-container>
</template>

<script scoped>
import { ref, computed, onMounted } from 'vue'
import { useStore } from '../store/store'
import apiClientService from '../services/apiClientService'
import { useRouter } from 'vue-router'
import countries from '../components/Countries.js'

export default {
  setup() {
    const store = useStore()
    const errorMessages = ref('')
    const ajaxError = ref(false)
    const isLoading = ref(false)
    const usersDirectLead = ref([])
    const duration = ref(300)
    const offset = ref(0)
    const easing = ref('easeInOutCubic')
    const customerInfoForm = ref(null)
    const router = useRouter()

    const emailRules = computed(() => [
      v => !!v || 'Email is required',
      v => !/\s/.test(v) || 'Email cannot contain spaces',
      v => /^[a-zA-Z0-9._@-]+$/.test(v) || 'Email contains non-valid characters',
      v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Email must be valid',
    ])

    const customerInfo = computed(() => store.customerInfo)

    onMounted(() => {
      if (!usersDirectLead.value.length) {
        isLoading.value = true
        apiClientService.getUsersFromBrand(store.currentBrand.id)
          .then(response => {
            usersDirectLead.value = response.data
            ajaxError.value = false
          })
          .catch(error => {
            ajaxError.value = error.message
          }).finally(() => {
            isLoading.value = false
          })
      }
    })

    async function submit() {
    const isValid = await customerInfoForm.value.validate();

    if (isValid.valid && !containsEmojiInForm()) {
      router.push({ name: 'lets-confirm' });
    } else {
      focusInvalidInput();
    }
}

const emojiRule = (value) => {
  if (containsEmoji(value)) {
        return 'Emojis are not allowed.';
      }
  return true;
}


const containsEmojiInForm = () => {
  const elements = Array.from(customerInfoForm.value.elements);

  return elements.some(input => containsEmoji(input.value));
}

const containsEmoji = (str) => {
  return /[\uD800-\uDFFF][\uDC00-\uDFFF]/.test(str);
}

    function focusInvalidInput() {
      const elements = customerInfoForm.value.elements || [];
  
      for (const element of elements) {
    if (element.validity && !element.validity.valid || containsEmoji(element.value)) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      element.focus();
      return;
    }
  }
    }

    return {
      countries,
      errorMessages,
      ajaxError,
      isLoading,
      usersDirectLead,
      duration,
      offset,
      easing,
      emailRules,
      customerInfo,
      submit,
      focusInvalidInput,
      customerInfoForm,
      emojiRule
    }
  }
}
</script>

<style>
.v-list-subheader__text {
  white-space: normal !important;
  text-overflow: clip;

}
</style>