<template>
  <!-- if current route is maintenance, render maintenance only -->
  <div v-if="currentRoute == 'maintenance'">
    <router-view :key="$route.fullPath"></router-view>
  </div>
    <div v-else>
      <v-app>
        <v-system-bar
        v-if="currentEnv == 'staging'"
        color="orange"
        class="text-center"
        style="font-size: 14px; height: 30px">
        <span style="width: 100%; color: white; font-weight: bold; font-family: 'Helvetica Neue',elvetica,Arial,sans-serif;">
          STAGING
        </span>
      </v-system-bar>
      <v-system-bar
        v-if="currentEnv == 'development'"
        color="#eecc00"
        class="text-center"
        style="font-size: 14px; height: 30px">
        <span style="width: 100%; color: black; font-weight: bold; font-family: 'Helvetica Neue',elvetica,Arial,sans-serif;">
          DEVELOPMENT
        </span>
      </v-system-bar>
  
      <v-app-bar
      color="#009a76"
      flat
        class="d-print-none"
        absolute
        >
        <v-toolbar-title class="pl-1">
          <div class="d-flex">
            <a href="/">
              <img
              src='./assets/prolead-logo.svg'
              class="logo"
              >
            </a>
          </div>
        </v-toolbar-title>
        <v-menu v-if="user">
          <template v-slot:activator="{ props }">
            <v-btn
            icon
            v-bind="props"
            >
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </template>
          <v-list>
            <v-list-item>          
                <v-list-item-title>
                  {{ user.first_name }} {{ user.last_name }}
                </v-list-item-title>
              <v-list-item-action>
                <router-link :to="{ name: 'who-are-you' }">
                  <v-icon>mdi-account-edit</v-icon>
                </router-link>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>
      
      <loading_spinner />
      
      <v-main id="prolead-content">
        <router-view v-slot="{ Component, route }">
          <component :is="Component" :key="route.path" />
        </router-view>
      </v-main>
      
      <div
      class="back-btn "
      v-if="currentRoute !== 'home' && currentRoute !== 'almost-done'"
      >
      <v-btn @click="checkRoute();" min-width="100%" min-height="100%" >
        <v-icon size="x-large" class="mr-3">mdi-menu-left</v-icon>
        BACK
      </v-btn>
    </div>
  </v-app>
</div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from './store/store.js'
import { useRouter, useRoute } from 'vue-router'

const store = useStore()
const router = useRouter()
const route = useRoute()

const user = ref(store.currentUser)
const currentRoute = computed(() => route.name)
const currentEnv = ref(import.meta.env.VITE_NODE_ENV)

const checkRoute = () => {
  if (currentRoute.value === 'who-is-this-for') {
    router.push({ path: '/who-are-you/' })
  } else {
    router.go(-1)
  }
}
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition-property: opacity;
  transition-duration: .25s;
}

.fade-enter-active {
  transition-delay: .25s;
}

.fade-enter, .fade-leave-active {
  opacity: 0
}

.logo {
  display: block;
  text-indent: -9999px;
  width: 240px;
  height: 100px;
}

#prolead-content {
  background-color: #fff;
}

#prolead-content h1 {
  color: #171b60;
  line-height: 1.1;
}  

#prolead-content #bottom-nav {
  background-color: #171b60;
}

.clickable {
  cursor: pointer;
}

.activated {
  text-decoration: underline;
}

.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important */
}

.v-card__title {
  font-size: 1rem;
  line-height: 1.25;
}

.back-btn {
  height: 60px;
}

.back-btn button {
  color: #00000099;
  font-size: 12px !important;
}

ul {
  padding-left: 40px !important;
}
</style>
